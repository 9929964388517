import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import Label from './Label';
import {StyledTextarea} from './Textarea.styles';
import ErrorMessage from './ErrorMessage';
import {sectionThemeNames} from '../../layout/Grid/Section.styles';
import {Input} from './Input';

export const CoreTextArea = forwardRef((props) => {
    const {name, label, isDisabled, errors} = props;
    return (
        <>
            {label && (
                <Label
                    htmlFor={name}
                    label={label}
                    error={errors && !!errors[name]}
                    disabled={isDisabled}
                />
            )}
            <StyledTextarea {...props} />
        </>
    );
});

export const Textarea = forwardRef((props, ref) => {
    const {
        name,
        label,
        theme,
        value,
        isDisabled,
        onChange,
        errors,
        ...rest
    } = props;
    return (
        <>
            {label && (
                <Label
                    htmlFor={name}
                    label={label}
                    error={errors && !!errors[name]}
                    disabled={isDisabled}
                />
            )}
            <StyledTextarea
                {...rest}
                aria-label={name}
                name={name}
                id={name}
                theme={theme}
                ref={ref}
                rows="8"
                cols="100"
                value={value}
                disabled={isDisabled}
                onChange={onChange}
            />
            {errors && errors[name] && (
                <ErrorMessage>{errors[name].message}</ErrorMessage>
            )}
        </>
    );
});

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object.isRequired,
};
CoreTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object.isRequired,
};

Input.defaultProps = {
    theme: 'default',
};

const ControlledTextarea = ({control, ...props}) => {
    return <Controller as={Textarea} control={control} {...props} />;
};

ControlledTextarea.propTypes = {
    control: PropTypes.object,
};

export default ControlledTextarea;
